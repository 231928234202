/* global axios */

class FlowsUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return process.env.VUE_APP_API_WA;
  }

  getFlows(wa_id) {
    return axios.ublux.get(
      `${this.url}/was/admin/provider/clients/flows/${wa_id}`
    );
  }

  sendFlow(flow_id) {
    return axios.ublux.get(
      `${this.url}/was/admin/provider/clients/flows/send-flow/${flow_id}`
    );
  }
}

export default new FlowsUbluxAPI();
