import types from '../../mutation-types';
import HubspotUbluxAPI from '../../../api/ublux/hubspotUblux';

export const state = {
  account: {},
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAccount: _state => {
    return _state.account;
  },
};

export const actions = {
  getAccount: async ({ commit }, inbox_id) => {
    commit(types.SET_HUBSPOT_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const response = await HubspotUbluxAPI.getAccount(inbox_id);
      commit(types.SET_HUBSPOT_ACCOUNT_UBLUX, response.data?.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_HUBSPOT_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_HUBSPOT_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_HUBSPOT_ACCOUNT_UBLUX]($state, data) {
    $state.account = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
